<template>
  <div class="university-store">
    <img class="us-title" :src="require('../../../assets/image/index/index_c.png')" />
    <div class="us-list">
      <div class="us-main"
           @click="goStoreDetail(item.id)"
           v-for="item in data && data.labels[0].objs.length>5?data.labels[0].objs.slice(0,5):data.labels[0].objs"
           :key="item.id"
      >
        <image-loader :width="100" :height="100" :src="item.pic" :mode="'cover'" />
        <div class="us-main-name ellipse-1"> {{ item.name }}</div>
        <div class="us-main-describe ellipse-5"> {{ item.introduction || "暂无简介" }}</div>
        <div class="us-main-enter">
          <span>进入店铺　</span>
          <span> > </span>
        </div>
      </div>
    </div>
    <div class="more-university" @click="goStoreList">更多高校></div>
  </div>

</template>

<script>
export default {
  name: "universityStore",
  props: {
    data: Object
  },
  data() {
    return {};
  },
  methods: {
    goStoreList() {
      this.$router.push({
        path: "/home/storeList",
        query: {
          tab: 2
        }
      });
      scrollTo(0, 0);
    },
    goStoreDetail(id) {
      this.$router.push({
          path: "/home/colleges",
          query: {
            shopId: id
          }
        }
      );
    }
  }
};
</script>

<style>
.university-store {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: rgba(247, 248, 250, 1);
}

.us-title {
  width: 677px;
  height: 52px;
  margin-top: 50px;
}

.us-list {
  width: 1200px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  height: 410px;
  align-items: center;
  margin-top: 10px;
}

.us-main {
  width: 216px;
  height: 369px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 25px 20px;
  background-color: #FFFFFF;
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.3s;
}

.us-main:hover {
  height: 406px;
  box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.08);
  border-radius: 5px;
}

.us-main-img {
  width: 100px;
  height: 100px;
}

.us-main-name {
  font-size: 16px;
  font-weight: 600;
  color: #484F64;
  margin-top: 10px;
}

.us-main-describe {
  margin-top: 5px;
  font-size: 14px;
  font-weight: 400;
  color: #484F64;
  line-height: 24px;
}

.us-main-enter {
  display: flex;
  flex-direction: row;
  font-size: 14px;
  font-weight: 400;
  color: #1890FF;
  margin-top: 28px;
}

.more-university {
  font-size: 14px;
  font-weight: 400;
  color: #1890FF;
  margin-top: 35px;
  margin-bottom: 55px;
  cursor: pointer;
}
</style>
